import styles from "../css/card.module.css";

function LocationCard({ img, city, date, time, winner }) {
  return (
    <article class={styles.card}>
      <img
        class={styles.cardbackground}
        src={img}
        alt=""
        width="1920"
        height="2193"
      />
      <div class={styles.cardcontent1}>
        <div class={styles.cardcontent2}>
          <h2 class={styles.cardtitle}>{city}</h2>
          <p class={styles.carddesc}>
            {winner}
            <br />
            {date}
            <br />
            {time}
          </p>
        </div>
      </div>
    </article>
  );
}

export default LocationCard;
