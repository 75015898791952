import styles from "../css/contact.module.css";
import { Contactcard } from "./index";

function Contact({ data }) {
  return (
    <div id="contactus" className={styles.contact}>
      <h1>Contact Us</h1>
      {data.length <= 0 ? (
        <h1>To be announced ...</h1>
      ) : (
        <div className={styles.cards}>
          {data.map((e) => {
            return (
              <Contactcard
                name={e.name}
                img={e.img.url}
                email={e.text1}
                post="Organizer"
                mobile={e.num1}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Contact;
