import styles from "../css/sponcard.module.css";

function SponCard({ img, link, name }) {
  return (
    <div className={styles.sponcard}>
      <div class={styles.box}>
        <div class={styles.card}>
          <div class={styles.imgBx}>
            <img className={styles.imgspon} src={img} alt="images" />
          </div>
          <div class={styles.details}>
            <h2>
              <a href={link}>{name}</a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SponCard;
