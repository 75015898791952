import {
  Navbar,
  About,
  Schedule,
  Sponsors,
  Register,
  Contact,
  Loader,
} from "../components/index";
import { getDoc, getFirestore, doc } from "firebase/firestore";
import { firebaseApp } from "../config";
import styles from "../css/home.module.css";
import { useState, useEffect } from "react";
const Home = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [contacts, setContacts] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, "WebContents", "comickaun");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let contacts = [];
      let schedule = [];
      let sponsors = [];
      for (let i = 0; i < rawData.data.length; i++) {
        switch (rawData.data[i].flag) {
          case "contact":
            contacts.push(rawData.data[i]);
            break;
          case "sponsor":
            sponsors.push(rawData.data[i]);
            break;
          case "schedule":
            schedule.push(rawData.data[i]);
            break;
        }
      }
      setContacts(contacts);
      setSchedule(schedule);
      setSponsors(sponsors);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.home}>
      <Navbar />
      <About />
      <Schedule data={schedule} />
      <Sponsors data={sponsors} />
      <Register />
      <Contact data={contacts} />
    </div>
  );
};

export default Home;
