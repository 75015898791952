import styles from "../css/sponsors.module.css";
import SponCard from "./SponCard";

function Sponsors({ data }) {
  return (
    <div id="sponsors" className={styles.sponsors}>
      <h1>Our Sponsors</h1>
      {data.length <= 0 ? (
        <h1>To be announced...</h1>
      ) : (
        <div className={styles.sponsorsList}>
          {data.map((e) => {
            return <SponCard name={e.name} link={e.text1} img={e.img.url} />;
          })}
        </div>
      )}
    </div>
  );
}

export default Sponsors;
