import styles from "../css/schedule.module.css";
import LocationCard from "./LocationCrad";

function Schedule({ data }) {
  return (
    <div id="schedule" className={styles.schedule}>
      <h1>Schedule</h1>
      {data.length <= 0 ? (
        <h1>To be announced...</h1>
      ) : (
        <div className={styles.cardsList}>
          {data.map((e) => {
            return (
              <LocationCard
                city={e.name}
                img={e.img.url}
                date={e.text1}
                time={e.text2}
                winner={e.text3}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Schedule;
