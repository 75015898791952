import styles from "../css/about.module.css";
import "animate.css";
import img from "../assets/mic3.png";
import comickaun from "../assets/ComicKaunLogo.png";
function About() {
  return (
    <div id="about" className={styles.aboutUs}>
      <div className={styles.leftAbout}>
        <h2>Antaragni’24</h2>
        <h3>Present's</h3>
        <img src={comickaun} style={{ width: "100%", height: "auto" }} />
      </div>
      <div className={styles.rightAbout}>
        <h1>About Us</h1>
        <p className={styles.desc}>
          Are you witty? Do your friends just love the timing of your jokes? Do
          you binge watch all the stand-up videos on YouTube with a feeling that
          you can do it better? Well, we have something for you. “Comic Kaun?”
          is Antaragni’s own version of a stand-up comic hunt, being organised
          for the first time in the 57th edition. Get ready to indulge in a
          battle of wit and timing with participants from all across the country
          and grab your mike as we get ready to answer the question - Comic
          Kaun?.
        </p>
      </div>
    </div>
  );
}

export default About;
