import React from "react";
import styles from "../css/contactcard.module.css";
import "animate.css";
function Contactcard(props) {
  return (
    <div class={`${styles.card} animate__animated animate__flip `}>
      <div class={styles.coverphoto}>
        <img src={props.img} class={styles.profile} alt="organiser" />
      </div>
      <h3 class={styles.profilename}>{props.name}</h3>
      <p class={styles.about}>
        {props.post}
        <br />
        Mobile: <a href={`tel:${props.mobile}`}>{props.mobile}</a>
        <br />
        <a href={`mailto:${props.email}`}>{props.email}</a>
      </p>
    </div>
  );
}

export default Contactcard;
