import { HashLink } from "react-router-hash-link";
import logo from "../assets/logo1.png";
import styles from "../css/navbar.module.css";
function Navbar() {
  return (
    <header className={styles.navbar}>
      <div className={styles.logo}>
        <img src={logo} alt="Antaragni logo" />
        <h2>Comickaun'24</h2>
      </div>

      <div className={styles.links}>
        <HashLink to="/#about" smooth={true}>
          About
        </HashLink>
        <HashLink to="/#schedule" smooth={true}>
          Schedule
        </HashLink>
        <HashLink to="/#sponsors" smooth={true}>
          Sponsors
        </HashLink>
        <HashLink to="/#register" smooth={true}>
          Register
        </HashLink>
        <HashLink to="/#contactus" smooth={true}>
          Contact Us
        </HashLink>
      </div>
    </header>
  );
}

export default Navbar;
