import styles from "./css/app.module.css";
import { Home } from "./pages/index";
import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </div>
  );
}

export default App;
