import styles from "../css/register.module.css";
//
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getFirestore, getDoc, setDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../config";
const db = getFirestore(firebaseApp);

function Register() {
  const [cities, setCities] = useState([]);
  const fetchData = async () => {
    try {
      const db = getFirestore(firebaseApp);
      const docRef = doc(db, "WebContents", "roadtripsCities");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let cities = [];
      for (let i = 0; i < rawData.data.length; i++) {
        if (rawData.data[i].flag === "comickaun") {
          cities.push(rawData.data[i].city);
        }
      }
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState([]);

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [performanceLink, setPerformanceLink] = useState("");
  const [micNum, setMicNum] = useState("");
  const [cityName, setCityName] = useState("");

  // Handling Input-Change
  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    }
    if (name === "emailId") {
      setEmailId(value);
    }
    if (name === "contactNumber") {
      setContactNumber(Number(value));
    }
    if (name === "alternateNumber") {
      setAlternateNumber(Number(value));
    }
    if (name === "performanceLink") {
      setPerformanceLink(value);
    }
    if (name === "micNum") {
      setMicNum(value);
    }
    if (name === "cityName") {
      setCityName(value);
    }
  }

  // reset form

  const resetForm = () => {
    setName("");
    setAlternateNumber("");
    setCityName("");
    setContactNumber("");
    setEmailId("");
    setMicNum("");
    setPerformanceLink("");
  };

  // generating Timestamp-ID
  function generateTimestampId() {
    const timestamp = firebase.firestore.Timestamp.now();
    const timestampString = timestamp.toDate().getTime().toString();
    const cleanedTimestampString = timestampString.replace(/[^a-zA-Z0-9]/g, "");
    return cleanedTimestampString;
  }

  // Handling Form-Submission
  async function handleFormSubmit(e) {
    e.preventDefault();
    const id = generateTimestampId();
    try {
      if (
        name != "" &&
        emailId != "" &&
        contactNumber != "" &&
        performanceLink != "" &&
        cityName != "" &&
        micNum != ""
      ) {
        await setDoc(doc(db, "comickaun24", id), {
          name: name.toUpperCase(),
          email: emailId,
          phone: contactNumber,
          altPhone: alternateNumber,
          performanceLink: performanceLink,
          micNum: micNum,
          cityName: cityName.toUpperCase(),
        });
        resetForm();
        alert("Form Submitted Successfully");
      } else {
        alert("Please Fill all the required fields !");
      }
    } catch (err) {
      console.error(err);
      alert("Error occurred while submitting the form !");
    }
  }

  return (
    <div id="register" className={styles.register}>
      <h1>Register</h1>
      <div className={styles.innerDiv}>
        <div className={styles.guidebox}>
          <h2 className={styles.guidetitle}>Guidelines</h2>
          <ol className={styles.guidelines}>
            <li>
              It is a solo competition. Time limit of the act is 7 minutes, with
              a penalty for going beyond it Performance can be in Hindi, English
              or both.
            </li>
            <li>
              Carrying chits or looking into mobile phones will result in
              penalty (however, you can come up with your own creative ideas).
            </li>
            <li>Props, of any kind, are not allowed.</li>
            <li>
              Content shouldn’t explicitly hurt the sentiments of any
              person/community.
            </li>
            <li>
              No explicit vulgarity or obscene content. Subtlety, however, is
              welcomed.
            </li>
          </ol>
        </div>
        <form onSubmit={(e) => handleFormSubmit(e)} className={styles.form}>
          <h2 className={styles.titleform}>Fill Details</h2>
          <br />
          <input
            name="name"
            value={name}
            onChange={handleInputChange}
            type="text"
            placeholder="Full Name*"
            required
          />
          <input
            name="emailId"
            value={emailId}
            onChange={handleInputChange}
            type="email"
            placeholder="Email*"
            required
          />
          <input
            name="contactNumber"
            value={contactNumber}
            onChange={handleInputChange}
            type="number"
            placeholder="Contact Number*"
            required
          />
          <input
            name="alternateNumber"
            value={alternateNumber}
            onChange={handleInputChange}
            type="number"
            placeholder="Alternate Number"
          />
          <input
            name="performanceLink"
            value={performanceLink}
            onChange={handleInputChange}
            type="url"
            placeholder="Past performance video drive links*"
            required
          />
          <input
            name="micNum"
            value={micNum}
            onChange={handleInputChange}
            type="number"
            placeholder="Number of open mics*"
            required
          />
          <select
            name="cityName"
            value={cityName}
            onChange={handleInputChange}
            required
          >
            <option value="">-- Select City --</option>
            {cities.map((city) => {
              return <option value={city}>{city}</option>;
            })}
          </select>
          <div className={styles.btnGroup}>
            <button onClick={resetForm}>Clear</button>
            <button>Submit</button>
          </div>

          <br />
        </form>
      </div>
    </div>
  );
}

export default Register;
